<script setup>
import { ref, defineExpose, reactive, computed, defineEmits, defineProps, watch, onBeforeMount } from 'vue';
import { useRouter } from 'vue-router';
import { helpers, required, requiredIf } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import DropdownElaboradorPgr from '../components/grid-unidades/DropdownElaboradorPgr.vue';
import { useAlert } from '../../../composables/useAlert.js';
import { getClientSesmt } from '../../../services/http-sesmt';
import { useMoment } from '../../../composables/useMoment';
import StatusGrid from './StatusGrid.vue';
import { StatusRegistroPendencia, TipoUsuarioRegistrarPendenciaEnum } from '../../../enums/StatusRegistroPendenciaEnum.js';
import AppConfirmDialog from '../../../components/AppConfirmDialog.vue';

const props = defineProps(['origem', 'origemId']);
const emit = defineEmits(['onSuccessPendencia']);

const { formatDateTz } = useMoment();
const { alertSucess, alertErrorData, alertWarn } = useAlert();
const { currentRoute } = useRouter();

const pendenciasColunas = [
    { field: 'descricao', header: 'Descrição', sortable: true, sortField: 'descricao' },
    { field: 'responsavel', header: 'Responsável', sortable: true, sortField: 'responsavelNome' },
    { field: 'dataCriacao', header: 'Data de criação', sortable: true, sortField: 'createdAt' },
    { field: 'dataConclusao', header: 'Data de conclusão', sortable: true, sortField: 'concluidoEm' },
    { field: 'status', header: 'Status', sortable: true, sortField: 'status' },
    {
        field: 'acoes',
        style: 'width: 55px',
        sortable: false
    }
];

const styleMapStatus = {
    [StatusRegistroPendencia.PENDENTE]: {
        backgroundColor: '#F6DFC3',
        color: '#653E11'
    },
    [StatusRegistroPendencia.EMAIL_ENVIADO]: {
        backgroundColor: '#C3F3F6',
        color: '#0D0540'
    },

    [StatusRegistroPendencia.PENDENCIA_RESOLVIDA]: {
        backgroundColor: '#C3F6D9',
        color: '#0E4005'
    }
};
const statusPendenciaOpcoes = ref([]);
const records = ref([]);
const isOpen = ref(false);
const isOpenResolved = ref(false);
const isOpenDelete = ref(false);
const isLoading = ref(false);
const menu = ref([]);
const loading = ref(false);
const isOpenRegistrarPendencia = ref(false);
const form = ref({ tipoUsuario: TipoUsuarioRegistrarPendenciaEnum.INTERNO });
const currentSortField = ref(null);
const currentSortOrder = ref(null);
const optionsDescricaoPendencia = ref(['Medição de ruído incorreta', 'Falta de cadastro de cargos', 'Falta de informações', 'Outros']);
const openEnviarEmail = ref(false);
const recordConfirmDialog = ref({});

const rules = reactive({
    tipo: {
        required: helpers.withMessage('A Descrição da pendência é obrigatória.', required),
        lazy: true
    },
    descricao: {
        required: helpers.withMessage('É necessário descrever a pendência.', required),
        lazy: true
    },
    tipoUsuario: {
        required: helpers.withMessage('O Tipo do usuário é obrigatório.', required),
        lazy: true
    },
    responsavelNome: {
        required: helpers.withMessage('O Responsável pela pendência é obrigatório.', required),
        validacaoPersonalizadaElaboradorPgr: helpers.withMessage('O responsável selecionado não possui usuário vinculado.', (value) => {
            if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
                return !!value?.usuarioVinculadoId;
            } else if (typeof value === 'string') {
                return value;
            }
        }),
        lazy: true
    },
    responsavelEmail: {
        required: helpers.withMessage(
            'E-mail do responsável é obrigatório',
            requiredIf(() => form.value?.enviarPorEmail)
        ),
        format: helpers.withMessage('O e-mail informado não é válido.', (value) => {
            if (!value || !value.length) {
                return true;
            }

            return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value.trim());
        }),
        lazy: true
    }
});

watch(
    () => form.value.responsavelEmail,
    () => validarCampoEmail()
);

watch(
    () => form.value.tipoUsuario,
    () => {
        if (form.value.tipoUsuario === TipoUsuarioRegistrarPendenciaEnum.INTERNO && !form.value.responsavelNome) form.value.responsavelNome = null;
    }
);

const pendencias = computed(() => records.value?.filter((p) => p.status === StatusRegistroPendencia.PENDENTE));

const getStatusPendenciaOpcoes = () => {
    statusPendenciaOpcoes.value = Object.keys(StatusRegistroPendencia).map(function (type) {
        return {
            label: `${StatusRegistroPendencia[type]}`,
            value: `${type}`,
            style: styleMapStatus[StatusRegistroPendencia[type]]
        };
    });
};

const getStatusComTodasAsConfigs = (status) => statusPendenciaOpcoes.value.find((p) => p.label === status);

const toggleMenu = async (event, index) => menu.value?.[index].toggle(event);

const getActionItems = (record) => {
    return [
        // TODO:
        // CRIAR AÇÃO NO BACKEND PARA O ENVIO DE E-MAIL
        {
            label: 'Enviar e-mail para o responsável',
            icon: 'pi pi-send',
            command: () => openConfirmDialog(record)
        },
        {
            label: 'Editar pendência',
            icon: 'pi pi-file-edit',
            // disabled: () => {},
            command: () => openDialog(record)
        },
        {
            label: 'Excluir pendência',
            icon: 'pi pi-trash',
            // disabled: () => {},
            command: () => {
                atribuirCampos(record);
                isOpenDelete.value = true;
            }
        },
        {
            label: 'Resolver pendência',
            icon: 'pi pi-check-square',
            // disabled: () => {},
            command: () => {
                atribuirCampos(record);
                isOpenResolved.value = true;
            }
        }
    ];
};

function openConfirmDialog(record) {
    recordConfirmDialog.value = record;
    openEnviarEmail.value = true;
}
async function enviarEmail() {
    try {
        await getClientSesmt().post(`aplicacao-pgr-elaboracao/elaborar/pendencia/enviar-email`, {
            pgElaboracaorId: recordConfirmDialog.value.pgrElaboracaoId,
            origem: recordConfirmDialog.value.origem,
            origemId: recordConfirmDialog.value.origemId,
            responsavelEmail: recordConfirmDialog.value.responsavelEmail,
            descricaoPendencia: recordConfirmDialog.value.descricao
        });
        alertSucess('E-mail enviado com sucesso!');
        openEnviarEmail.value = false;
    } catch (error) {
        const { message } = error?.response?.data;
        alertWarn(message);
    }
}

const load = async () => {
    try {
        loading.value = true;
        const pgrElaboracaoId = currentRoute.value.params.idElaboracao;
        const { data } = await getClientSesmt().get(
            `aplicacao-pgr-elaboracao/elaborar/pendencia/${props.origemId}/origem/${props.origem}/${pgrElaboracaoId}`
        );
        records.value = data;
    } catch (error) {
        const { message } = error?.response?.data;
        alertWarn(message);
    } finally {
        loading.value = false;
    }
};

const validarCampoEmail = () => v$.value.responsavelEmail.$touch();

const validarCampoElaboradorPgr = () => {
    if (form.value.tipoUsuario === TipoUsuarioRegistrarPendenciaEnum.INTERNO) {
        v$.value.responsavelNome.$touch();
        if (form.value?.responsavelNome?.usuarioVinculado?.email) form.value.responsavelEmail = form.value.responsavelNome.usuarioVinculado.email;
    }
};

const v$ = useVuelidate(rules, form);

const isDisable = computed(
    () =>
        !form.value?.tipo ||
        !form.value?.descricao ||
        !form.value?.tipoUsuario ||
        !form.value?.responsavelNome ||
        !form.value?.responsavelEmail?.length ||
        (v$.value.responsavelEmail.$errors.length && v$.value.responsavelEmail.lazy.$dirty)
);

const atribuirCampos = (pendencia) => {
    form.value.tipo = pendencia.tipo;
    form.value.descricao = pendencia.descricao;
    form.value.tipoUsuario = pendencia.tipoUsuario;
    form.value.responsavelNome = { nome: pendencia.responsavelNome, usuarioVinculadoId: pendencia.responsavelId };
    form.value.responsavelEmail = pendencia.responsavelEmail;
    form.value.id = pendencia.id;
};

const openDialog = async (pendencia) => {
    if (pendencia) {
        atribuirCampos(pendencia);
    }
    isOpen.value = true;
};

const closeDialog = () => {
    v$.value.$reset();
    form.value = {};
    isOpen.value = false;
    isOpenResolved.value = false;
    isOpenDelete.value = false;
};

const confirmacaoPendenciaDialog = reactive({
    voltar: () => {
        confirmacaoPendenciaDialog.aberta = false;
        isOpen.value = true;
    },
    abrir: () => {
        isOpen.value = false;
        confirmacaoPendenciaDialog.aberta = true;
    },
    confirmar: async () => {
        await salvar();
        confirmacaoPendenciaDialog.aberta = false;
    },
    aberta: false
});

const isEditar = computed(() => !!form.value.id);
async function confirmarPendencia() {
    if (isEditar.value) {
        await salvar();
        return;
    }
    confirmacaoPendenciaDialog.abrir();
}

const salvar = async (status) => {
    try {
        const isValid = await v$.value.$validate();
        if (!isValid) return;

        loading.value = true;
        const pgrElaboracaoId = currentRoute.value.params.idElaboracao;

        await getClientSesmt().post(`aplicacao-pgr-elaboracao/elaborar/pendencia`, {
            id: form.value?.id,
            delete: isOpenDelete.value ? true : false,
            pgrElaboracaoId: pgrElaboracaoId,
            status: status ? status : 'PENDENTE',
            descricao: form.value?.descricao,
            origem: props.origem,
            origemId: props.origemId === 0 ? null : props.origemId,
            tipo: form.value?.tipo,
            responsavelId:
                form.value.tipoUsuario === TipoUsuarioRegistrarPendenciaEnum.INTERNO ? form.value?.responsavelNome.usuarioVinculadoId : null,
            responsavelNome:
                form.value.tipoUsuario === TipoUsuarioRegistrarPendenciaEnum.INTERNO ? form.value?.responsavelNome.nome : form.value?.responsavelNome,
            responsavelEmail: form.value.responsavelEmail,
            tipoUsuario: form.value?.tipoUsuario
        });

        alertSucess('Pendência registrada com sucesso.');
        closeDialog();
        emit('onSuccessPendencia');
        await load();
    } catch (error) {
        if (error?.response?.data?.details?.response?.retornoErrosCadastro?.contemErrosCadastro) {
            closeDialog();
            return;
        }

        alertErrorData(error, 'Não foi salvar registro, por favor tente novamente.');
    } finally {
        loading.value = false;
    }
};

const init = async () => {
    getStatusPendenciaOpcoes();
    await load();
};

defineExpose({ pendencias });
onBeforeMount(init);
</script>
<template>
    <div>
        <div class="mb-5w-full flex flex-row justify-content-between align-items-center">
            <strong class="titulo">Registro de pendência</strong>
            <Button
                rounded
                v-if="!isOpenRegistrarPendencia"
                label="Registrar pendência"
                icon="pi pi-plus"
                class="p-button-text"
                @click="openDialog()"
            />
        </div>
        <DataTable
            :value="records"
            class="mt-5"
            dataKey="id"
            lazy
            :loading="loading"
            loadingIcon="pi pi-spinner"
            :sortField="currentSortField"
            :sortOrder="currentSortOrder"
            removableSort
        >
            <template #empty>Nenhuma pendência registrada.</template>

            <Column
                v-for="col of pendenciasColunas"
                :field="col.field"
                :key="col.field"
                :style="col.style"
                :sortable="col.sortable"
                :sortField="col.sortField"
            >
                <template #header>
                    <div class="font-semibold">{{ col.header }}</div>
                </template>

                <template #body="{ data, index }">
                    <template v-if="col.field === 'acoes'">
                        <Button class="p-button-text p-button-secondary" icon="pi pi-ellipsis-v" @click="toggleMenu($event, index)" />
                        <Menu :ref="(el) => (menu[index] = el)" :model="getActionItems(data)" :popup="true" />
                    </template>

                    <template v-else-if="col.field === 'descricao'">
                        {{ data.descricao }}
                    </template>

                    <template v-else-if="col.field === 'responsavel'">
                        {{ data.responsavelNome }}
                    </template>
                    <template v-else-if="col.field === 'dataCriacao'">{{ formatDateTz(data.createdAt) }} </template>
                    <template v-else-if="col.field === 'dataConclusao'">
                        {{ data.concluidoEm ? formatDateTz(data.concluidoEm) : null }}
                    </template>
                    <template v-else-if="col.field === 'status'"> <StatusGrid :status="getStatusComTodasAsConfigs(data.status)" /> </template>

                    <template v-else>
                        <span>{{ data[col.field] }}</span>
                    </template>
                </template>
            </Column>
        </DataTable>
        <Dialog
            :visible="isOpenResolved || isOpenDelete"
            :style="{ width: '500px', height: 'auto' }"
            :header="isOpenResolved ? 'Resolver pendência' : 'Excluir pendência'"
            @hide="
                () => {
                    isOpenResolved = false;
                    isOpenDelete = false;
                }
            "
            modal
            :closable="!isLoading"
        >
            <div class="flex flex-column w-full">
                <strong class="mb-3">
                    {{ isOpenDelete ? 'Você realmente deseja excluir a pendência abaixo?' : 'Você deseja resolver a pendência abaixo?' }}
                </strong>
                <span> {{ form.descricao }}</span>
                <div class="flex aling-itens-end pt-4">
                    <Button
                        label="Cancelar"
                        class="p-button-text justify-content-end"
                        @click="
                            () => {
                                isOpenResolved = false;
                                isOpenDelete = false;
                            }
                        "
                    />
                    <Button
                        label="Confirmar"
                        class="p-button-primary justify-content-end"
                        @click="salvar(isOpenResolved ? 'PENDÊNCIA RESOLVIDA' : null)"
                        :loading="loading"
                    />
                </div>
            </div>
        </Dialog>
        <Dialog
            v-model:visible="isOpen"
            :style="{ width: '500px', height: 'auto' }"
            header="Registrar pendência"
            @hide="!confirmacaoPendenciaDialog.aberta && closeDialog()"
            modal
            :closable="!isLoading"
        >
            <div class="flex flex-column w-full">
                <div class="flex flex-column w-full mb-4">
                    <label class="mb-2" for="setor">Descrição da pendência</label>
                    <Dropdown
                        v-model="v$.tipo.$model"
                        :class="{ 'p-invalid': v$.tipo.$errors.length && v$.tipo.lazy.$dirty }"
                        :options="optionsDescricaoPendencia"
                        placeholder="Selecionar"
                        checkmark
                        :highlightOnSelect="false"
                        class="w-full"
                        showClear
                    />
                    <small v-if="v$.tipo.$errors.length && v$.tipo.lazy.$dirty" class="p-error">
                        {{ v$.tipo.$errors[0].$message }}
                    </small>
                </div>
                <div v-if="v$.tipo.$model" class="flex flex-column w-full mb-4">
                    <label class="mb-2" for="descricaoRisco">Descreva a pendência</label>
                    <InputText
                        type="text"
                        v-model="v$.descricao.$model"
                        :class="{ 'p-invalid': v$.descricao.$errors.length && v$.descricao.lazy.$dirty }"
                        class="w-full"
                    />
                    <small v-if="v$.descricao.$errors.length && v$.descricao.lazy.$dirty" class="p-error">
                        {{ v$.descricao.$errors[0].$message }}
                    </small>
                </div>
                <div class="flex flex-column w-full mb-4">
                    <span class="mb-4">Tipo de usuário</span>
                    <div class="flex flex-wrap gap-3">
                        <div class="flex align-items-center">
                            <RadioButton
                                v-model="v$.tipoUsuario.$model"
                                :class="{ 'p-invalid': v$.tipoUsuario.$errors.length && v$.tipoUsuario.lazy.$dirty }"
                                inputId="piom"
                                name="piom"
                                :value="TipoUsuarioRegistrarPendenciaEnum.INTERNO"
                            />
                            <label for="piom" class="ml-2">Usuário PIOM</label>
                        </div>
                        <div class="flex align-items-center">
                            <RadioButton
                                v-model="v$.tipoUsuario.$model"
                                :class="{ 'p-invalid': v$.tipoUsuario.$errors.length && v$.tipoUsuario.lazy.$dirty }"
                                inputId="externo"
                                name="externo"
                                :value="TipoUsuarioRegistrarPendenciaEnum.EXTERNO"
                            />
                            <label for="externo" class="ml-2">Usuário externo</label>
                        </div>
                    </div>
                    <small v-if="v$.tipoUsuario.$errors.length && v$.tipoUsuario.lazy.$dirty" class="p-error">
                        {{ v$.tipoUsuario.$errors[0].$message }}
                    </small>
                </div>
                <div v-if="v$.tipoUsuario.$model == TipoUsuarioRegistrarPendenciaEnum.INTERNO" class="flex flex-column field">
                    <label for="responsavel">Responsável pela pendência </label>
                    <DropdownElaboradorPgr
                        id="responsavel"
                        class="w-full"
                        v-model="v$.responsavelNome.$model"
                        :filtrosExtras="null"
                        :class="{ 'p-invalid': v$.responsavelNome.$errors.length && v$.responsavelNome.lazy.$dirty }"
                        showClear
                        @change="validarCampoElaboradorPgr"
                    />
                    <small v-if="v$.responsavelNome.$errors.length && v$.responsavelNome.lazy.$dirty" class="p-error">
                        {{ v$.responsavelNome.$errors[0].$message }}
                    </small>
                </div>
                <div v-else class="flex flex-column w-full mb-4">
                    <label class="mb-2" for="descricaoRisco">Responsável pela pendência</label>
                    <InputText
                        type="text"
                        v-model="v$.responsavelNome.$model"
                        :class="{ 'p-invalid': v$.responsavelNome.$errors.length && v$.responsavelNome.lazy.$dirty }"
                        class="w-full"
                    />
                    <small v-if="v$.responsavelNome.$errors.length && v$.responsavelNome.lazy.$dirty" class="p-error">
                        {{ v$.responsavelNome.$errors[0].$message }}
                    </small>
                </div>
                <div class="flex flex-column w-full mb-4">
                    <label class="mb-2" for="descricaoRisco">E-mail do responsável</label>
                    <InputText
                        type="text"
                        v-model="v$.responsavelEmail.$model"
                        :class="{ 'p-invalid': v$.responsavelEmail.$errors.length && v$.responsavelEmail.lazy.$dirty }"
                        class="w-full"
                    />
                    <small v-if="v$.responsavelEmail.$errors.length && v$.responsavelEmail.lazy.$dirty" class="p-error">
                        {{ v$.responsavelEmail.$errors[0].$message }}
                    </small>
                </div>
            </div>
            <template #footer>
                <div class="flex aling-itens-end pt-4">
                    <Button label="Cancelar" class="p-button-text justify-content-end" @click="closeDialog()" />
                    <Button
                        :disabled="isDisable"
                        label="Confirmar"
                        class="p-button-primary justify-content-end"
                        @click="confirmarPendencia"
                        :loading="loading"
                    />
                </div>
            </template>
        </Dialog>
        <AppConfirmDialog
            v-model:visible="confirmacaoPendenciaDialog.aberta"
            confirmLabel="Confirmar"
            title="O registro principal ficará pendente até que este item seja resolvido ou removido."
            :loading="loading"
            @onConfirm="confirmacaoPendenciaDialog.confirmar"
            @onClose="confirmacaoPendenciaDialog.voltar"
        />
        <AppConfirmDialog
            v-if="openEnviarEmail"
            v-model:visible="openEnviarEmail"
            :confirmHeader="'Deseja enviar e-mail de notificação ao ' + recordConfirmDialog.responsavelEmail + '?'"
            confirmLabel="Enviar"
            @onConfirm="enviarEmail"
            @onClose="openEnviarEmail = false"
            noIcon
        />
    </div>
</template>
